<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
				<el-form-item label="姓名:">
          <el-input 
						v-model.trim="searchForm.studentname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="院校:">
          <el-input 
						v-model.trim="searchForm.collegename1" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="专业:">
          <el-input 
						v-model.trim="searchForm.majorname1" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="层次:">
          <el-select 
						v-model="searchForm.levelval" 
						@visible-change="visibleType"
						@keyup.enter.native="seach"
						ref="projecttyperef"
						clearable>
            <el-option
              v-for="item in leveltype"
              :value="item.val"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级:">
          <el-input 
						v-model.trim="searchForm.grade" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="是否禁用:">
					<el-select 
						v-model="searchForm.isavailable"
						@visible-change="visibleisavailable"
						@keyup.enter.native="seach"
						ref="availableref"
						clearable 
						>
							<el-option value="0" label="否"></el-option>
							<el-option value="1" label="是"></el-option>
						</el-select>
        </el-form-item>
				<el-form-item label="是否编辑:">
					<el-select 
						v-model="searchForm.isintegrity"
						@visible-change="isintegrity"
						@keyup.enter.native="seach"
						ref="isintegrityref"
						clearable 
					>
					<el-option value="0" label="否"></el-option>
					<el-option value="1" label="是"></el-option>
					</el-select>
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			
			<el-table-column label="姓名" align="center" prop="studentname"></el-table-column>
      <el-table-column label="院校" align="center" prop="collegename1" width="200">
      </el-table-column>
      <el-table-column
        label="专业"
        prop="majorname1"
        align="center"
        width="200"
      />
      <el-table-column
        label="层次"
        prop="levelval"
        align="center"
        width="80"
      />
      <el-table-column
        label="年级"
        prop="grade"
        align="center"
      />
			<el-table-column label="手机号" prop="mobile" align="center" width="120">
				<template slot-scope="scope" v-if="scope.row.mobile !== null">
					  {{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
				</template>
			</el-table-column>
			<el-table-column label="身份证" prop="idcard" align="center" width="125">
				<template slot-scope="scope">
					{{scope.row.idcard | onlyIDcard()}}
				</template>
			</el-table-column>
			<el-table-column label="是否可编辑" align="center" width="100px">
			  <template slot-scope="scope">
			    {{ available[scope.row.isintegrity] }}
			  </template>
			</el-table-column>
			<el-table-column label="是否禁用" align="center" width="100px">
			  <template slot-scope="scope">
			    {{ available[scope.row.isavailable] }}
			  </template>
			</el-table-column>
      <el-table-column
        label="流水号"
        prop="id"
        align="center"
      />
     
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
    <!--添加，修改-->
    <el-dialog
			class="btn-2b5a95"
			width="70%"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
				<el-form 
					:model="dynamicValidateForm" 
					ref="dynamicValidateForm" 
					label-width="170px"
					class="demo-dynamic form-item-w-50" 
					status-icon :rules="rules">
				<el-form-item label="题库">
					<template>
						<el-checkbox-group
							v-model="dynamicValidateForm.question" 
						   >
						   <div v-for="(date, adIndex) in questionList" :key="adIndex">
						     <el-checkbox
								:label="date.id"
						       >
							   {{date.questionname}}
							</el-checkbox>	
								<el-form-item style="margin-left: 20px; margin-bottom: 32px;">
									<el-date-picker
											v-model="questionList[adIndex].questionvalidity"
											type="date"
											@change="startTime($event, adIndex)"
											value-format="yyyy-MM-dd"
											placeholder="请设置课程有效期">
									</el-date-picker>
								</el-form-item>
							</div>
						   </el-checkbox-group>
					</template>
				</el-form-item>
				<el-form-item label="网课">
					<template>
						<el-checkbox-group
							v-model="dynamicValidateForm.course" 
						   >
						   <div v-for="(lay, adIndex) in videoList" :key="adIndex">
						     <el-checkbox
								:label="lay.id"	
						       >
							   {{lay.coursename}}
							</el-checkbox>	
								<el-form-item style="margin-left: 20px; margin-bottom: 32px;">
									
									<el-date-picker
											v-model="videoList[adIndex].coursevalidity"
											type="date"
											@change="onChangeQuestion($event, adIndex)"
											value-format="yyyy-MM-dd"
											placeholder="请设置网课有效期">
									</el-date-picker>
								</el-form-item>
							</div>
						   </el-checkbox-group>
					</template>
				</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>
import { _available } from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  studentuser_list,
	studentuser_input,
	studentuser_savestudent,
	studentuser_saveisavailable,
	studentuser_saveisintergrity,
	videocourse_dialogresult,
	question_dialogresult,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "management",
  components: {},
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
		rules: {
		},
		multipleSelection: [], //选中的表格项
      showAddModal: false, //添加
      dynamicValidateForm: {
	  }, //表单
      	dialogTitle: "", //对话框标题
		leveltype: [],
		available: _available,
		questionList: [],
		videoList: [],
		questionAll: [],
		courseAll: [],
		isChecked: false
    };
  },
  created() {
    this.getDataList();
		this.dic_combox({
		  list: "leveltype",
		  typecode: "leveltype",
		  that: this,
		});
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.projecttyperef.blur();
			}
		},
		visibleisavailable(e) {
			if(!e) {
				this.$refs.availableref.blur();
			}
		},
		isintegrity(e) {
			if(!e) {
				this.$refs.isintegrityref.blur();
			}
		},
		reset() {
			this.searchForm = {
				studentname: null,
				collegename1: null,
				majorname1: null,
				levelval: null,
				grade: null,
				isavailable: null,
				isintegrity: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentuser_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		startTime(val, index) {
			this.questionAll[index] = formatDare(this.questionList[index].questionvalidity,"YYYY-MM-DD HH:mm:ss");
			console.log(this.questionAll, '---')
		},
		onChangeQuestion(val, index) {
			this.courseAll[index] =	 formatDare(this.videoList[index].coursevalidity ,"YYYY-MM-DD HH:mm:ss");
			console.log(this.courseAll, 'videoList')
		},
		videocourse() {
				myRequest({
					url: videocourse_dialogresult,
					data: {},
				}).then((res) => {
					if (res.data.code === "200") {
						this.videoList = res.data.data.list
					}
				});

			},
			question() {
				myRequest({
					url: question_dialogresult,
					data: {},
				}).then((res) => {
					if (res.data.code === "200") {
						this.questionList = res.data.data.list;
					}
				});

			},
    //修改click
    btnEdit() {
			this.dialogTitle = "改课";
      selectCheck(this.multipleSelection, "修改", false, () => {
					this.classRoutePage(this.multipleSelection[0].id);
					this.dynamicValidateForm = {
						course: [],
						question: [],
						id: this.multipleSelection[0].id,
					}
				});
    },
		// 是否可编辑
		btnExited() {
			selectCheck(this.multipleSelection, "编辑", true, () => {
			  confirmCallBack({
			    title: "提示",
			    content: "此操作是否可编辑选中的数据！",
			    success: () => {
			      this.exitedItem(this.multipleSelection[0].id, 1);
			    },
			    fail: () => {
			      messageTip({
			        message: "取消操作",
			        type: "info",
			      });
						this.exitedItem(this.multipleSelection[0].id, 0);
			    },
			  });
			});
			
		},
		exitedItem(id, isintegrity) {
		  myRequest(
		    {
		      method: "post",
		      url: studentuser_saveisintergrity,
		      data: this.$qs.stringify(
		        {
		          id: id,
							isintegrity: isintegrity
		        },
		        {
		          arrayFormat: "repeat",
		        }
		      ),
		    },
		    {
		      isRefTableList: true,
		      that: this,
		    }
		  );
		},
		// 是否可禁用
		btnDisabled() {
			selectCheck(this.multipleSelection, "禁用", true, () => {
			  confirmCallBack({
			    title: "提示",
			    content: "此操作将永久禁用选中的数据！",
			    success: () => {
			      this.isabledItem(this.multipleSelection[0].id, 1);
			    },
			    fail: () => {
			      messageTip({
			        message: "取消操作",
			        type: "info",
			      });
				  this.isabledItem(this.multipleSelection[0].id, 0);
			    },
			  });
			});
		},
		isabledItem(id, isavailable) {
		  myRequest(
		    {
		      method: "post",
		      url: studentuser_saveisavailable,
		      data: this.$qs.stringify(
		        {
		          	id: id,
					isavailable: isavailable
		        },
		        {
		          arrayFormat: "repeat",
		        }
		      ),
		    },
		    {
		      isRefTableList: true,
		      that: this,
		    }
		  );
		},
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //修改路由页 api
    classRoutePage(id) {
				myRequest({
					url: studentuser_input,
					data: {
						id: id,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.dynamicValidateForm.studentid = res.data.data.tstudentuser.studentid
						this.showAddModal = true;
						this.question();
						this.videocourse();
					}
				});

			},
    //提交-（添加，修改） api
    submitAddOrEditForm(dynamicValidateForm) {
				this.dynamicValidateForm.coursevalidity = this.courseAll
				console.log(this.dynamicValidateForm.coursevalidity, 'coursevalidity')
				this.dynamicValidateForm.questionvalidity = this.questionAll
				console.log(	this.dynamicValidateForm.questionvalidity, 'this.questionvalidity')
					this.$refs[dynamicValidateForm].validate((valid) => {
						if (valid) {
							myRequest({
								method: "post",
								url: studentuser_savestudent,
								data: this.$qs.stringify(this.dynamicValidateForm, {
									arrayFormat: 'repeat'
							}),
							},{
								that: this,
								isRefTableList: true,
								modal: "showAddModal",
							})
						}
						console.log(this.dynamicValidateForm, '这个是---')
					})
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style lang="scss">
	
</style>